@import './styles/variables.css';

/* Base styles for the application */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  -webkit-tap-highlight-color: var(--tap-highlight-color);
}

/* Homepage body styles */
.homepage-body {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Improved touch targets */
button,
a,
input[type="button"],
input[type="submit"] {
  min-height: 44px;
  min-width: 44px;
}

/* Touch feedback for interactive elements */
a:active,
button:active,
.btn:active,
.nav-link:active {
  opacity: var(--active-opacity);
  transition: opacity var(--transition-fast);
}

/* Improved focus states */
button:focus,
a:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--focus-ring-color);
}

/* Fluid typography */
html {
  font-size: 16px;
  scroll-behavior: smooth;
  height: 100%;
  overflow-y: auto;
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

/* Prevent content from being hidden under fixed nav */
main {
  padding-top: 5rem;
  padding-bottom: 0;
  transition: padding-top var(--transition-normal);
  min-height: calc(100vh - 56px);
}

@media (max-width: 768px) {
  main {
    padding-top: 4.5rem;
    padding-bottom: 0;
    transition: padding-top var(--transition-normal);
  }
}

/* Improve form fields on mobile */
input, select, textarea {
  font-size: 16px !important; /* Prevents zoom on focus in iOS */
}

/* Homepage containers */
.home-portrait-container, .home-landscape-container {
  display: flex;
  min-height: calc(100vh - 10rem);
  padding: 5rem 0 2rem 0;
  align-items: center;
  justify-content: center;
}

.home-content {
  background-color: white;
  padding: clamp(1.5rem, 4vw, 2.5rem);
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.5) 100%);
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.home-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2) !important;
}

.home-content h2 {
  font-weight: 700;
  font-size: clamp(1.8rem, 5vw, 2.8rem);
  letter-spacing: 0.5px;
  line-height: 1.3;
}

.home-content h4 {
  font-weight: 400;
  font-size: clamp(1.2rem, 3vw, 1.6rem);
  line-height: 1.5;
  opacity: 0.9;
}

.home-content p {
  font-weight: 300;
  line-height: 1.7;
  font-size: clamp(1rem, 2vw, 1.2rem);
}

.home-cta {
  display: inline-block;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 1rem;
  border-radius: 4px;
  position: relative;
  z-index: 10;
  cursor: pointer;
  pointer-events: auto;
}

.home-cta:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.home-cta:active {
  transform: scale(0.98);
}

/* Add responsive styles for homepage consistent with maintenance page */
@media (max-width: 992px) {
  .home-portrait-container, .home-landscape-container {
    padding: 5rem 0 2rem 0;
  }
}

@media (max-width: 768px) {
  .home-portrait-container, .home-landscape-container {
    padding: 4rem 0 2rem 0;
    margin-bottom: 4rem;
  }

  .home-content {
    padding: 1.8rem;
  }

  .home-content h2 {
    font-size: 1.8rem;
  }

  .home-content h4 {
    font-size: 1.4rem;
  }
}

@media (max-width: 576px) {
  .home-portrait-container, .home-landscape-container {
    padding: 3rem 0 1.5rem 0;
  }

  .home-content {
    padding: 1.5rem;
  }

  .home-content h2 {
    font-size: 1.7rem;
  }

  .home-content h4 {
    font-size: 1.2rem;
  }

  .home-content p {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .home-content {
    padding: 1.2rem;
  }

  .home-content h2 {
    font-size: 1.5rem;
    margin-bottom: 0.75rem !important;
  }

  .home-content h4 {
    font-size: 1.1rem;
    margin-bottom: 0.75rem !important;
  }

  .home-content p {
    font-size: 1rem;
    margin-bottom: 0.75rem !important;
  }
}

/* Content pages styling */
.content-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.content-container h3 {
  color: #2d3748;
  font-weight: 600;
  font-size: 1.8rem;
  border-bottom: 2px solid #edf2f7;
  padding-bottom: 0.75rem;
}

.content-container h4 {
  color: #4a5568;
  font-weight: 600;
  font-size: 1.4rem;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.content-container p,
.content-container ul,
.content-container li {
  color: #4a5568;
  line-height: 1.7;
}

.content-container a {
  color: #3182ce;
  text-decoration: none;
  transition: color 0.2s;
}

.content-container a:hover {
  color: #2c5282;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .content-container {
    padding: 1.5rem;
  }

  .content-container h3 {
    font-size: 1.6rem;
  }

  .content-container h4 {
    font-size: 1.3rem;
  }
}

/* Mobile landscape view adjustments */
@media (max-height: 500px) and (orientation: landscape) {
  body {
    overflow-y: auto;
    height: auto;
  }

  .bg {
    min-height: auto;
    height: auto;
    padding: 1rem 0 4rem;
    display: block;
    overflow-y: auto;
    position: relative;
  }

  .homepage-bg {
    margin-bottom: 0;
    padding-bottom: 4rem;
  }

  .home-content {
    padding: 1rem;
    max-width: 90%;
    margin: 1rem auto 4rem;
    transform: none;
  }

  .home-content h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .home-content h4 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }

  .home-content p {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    line-height: 1.4;
    max-height: none;
    overflow: visible;
  }

  .home-cta {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

/* Deeper landscape adjustments for very short heights */
@media (max-height: 400px) and (orientation: landscape) {
  body, html {
    overflow-y: auto !important;
  }

  .bg {
    min-height: auto !important;
    position: relative !important;
    display: block !important;
    overflow-y: visible !important;
  }

  .home-content {
    transform: none;
    padding: 0.75rem;
    margin-bottom: 5rem;
  }
}

/* Home-specific content container for landscape mode */
.home-landscape {
  color: white !important;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.5) 100%) !important;
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  border: 2px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  padding: clamp(1.5rem, 4vw, 2.5rem) !important;
}

.home-landscape:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2) !important;
}

.home-landscape h2,
.home-landscape h4 {
  color: white !important;
}

.home-landscape h2 {
  font-weight: 700;
  font-size: clamp(1.8rem, 5vw, 2.8rem);
  line-height: 1.3;
  border-bottom: none !important;
}

.home-landscape h4 {
  font-weight: 400;
  font-size: clamp(1.2rem, 3vw, 1.6rem);
  line-height: 1.5;
}

.home-landscape p {
  color: rgba(255, 255, 255, 0.9) !important;
  font-weight: 300;
  line-height: 1.7;
  font-size: clamp(1rem, 2vw, 1.2rem);
}

/* Adjust landscape mode to use content-container approach */
@media (max-height: 500px) and (orientation: landscape) {
  body {
    background-attachment: fixed !important;
  }

  .home-portrait-container, .home-landscape-container {
    padding: 2rem 0;
  }

  .home-content, .home-landscape {
    padding: 1.5rem !important;
  }

  .home-content h2, .home-landscape h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem !important;
  }

  .home-content h4, .home-landscape h4 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem !important;
  }

  .home-content p, .home-landscape p {
    font-size: 0.9rem;
    line-height: 1.4;
    margin-bottom: 0.5rem !important;
  }

  .home-cta {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}
.footer {
  padding: var(--space-md) 0;
  position: relative;
  z-index: var(--z-index-footer);
  background-color: var(--primary);
  width: 100%;
  color: var(--text-light);
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--space-md);
}

.copyRight {
  color: var(--text-light);
}

.footerLinks {
  display: flex;
  gap: var(--space-lg);
  flex-wrap: wrap;
  justify-content: center;
}

.footerLink {
  color: var(--text-light) !important;
  text-decoration: none !important;
  transition: color var(--transition-fast);
  cursor: pointer;
  min-height: 44px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.footerLink:hover {
  color: var(--text-light-muted) !important;
}

.socialSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--space-sm);
}

.coffeeButton {
  display: block;
}

.coffeeImage {
  height: 30px;
  width: auto;
}

.socialLinks {
  display: inline-block;
  margin-top: 0.8rem;
}

.socialLink {
  color: var(--text-light);
  text-decoration: none;
  margin-right: 1.5rem;
  font-size: 1.2rem;
  transition: color var(--transition-fast);
}

.socialLink:last-child {
  margin-right: 0;
}

.socialLink:hover {
  color: var(--text-light-muted);
}

.poweredBy {
  margin-top: 0.8rem;
}

.stravaLogo {
  height: 30px;
  width: auto;
}

@media (max-width: 768px) {
  .footerContent {
    flex-direction: column;
    text-align: center;
    gap: var(--space-md);
  }

  .footerLinks {
    justify-content: center;
    gap: var(--space-md);
  }

  .socialSection {
    align-items: center;
    margin-top: var(--space-md);
  }

  .socialLinks {
    margin-top: var(--space-md);
  }
}

@media (max-width: 576px) {
  .socialLink {
    margin-right: 1rem;
  }
}
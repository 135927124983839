.navbarBrand {
  cursor: pointer;
}

.brandLogo {
  display: inline-block;
  align-top: top;
}

.branding {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.brandingTitle {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 1.5rem;
  margin: 0;
  letter-spacing: 7px;
}

.brandingSpan {
  display: inline-block;
}

.brandLogoPrimary {
  color: var(--text-light);
  font-size: 2rem;
}

.brandLogoSecondary {
  color: var(--text-light);
}

.brandLogoTertiary {
  color: var(--text-light-muted);
  font-size: 2rem;
}

.brandLogoQuaternary {
  color: var(--text-light-muted);
}

.navLink {
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  min-height: 44px;
  display: flex;
  align-items: center;
}

.navbarCollapse {
  transition: all var(--transition-normal);
}

.navLinkActive {
  color: white !important;
  position: relative;
}

.navLinkActive::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0.75rem;
  right: 0.75rem;
  height: 2px;
  background-color: white;
  opacity: 0.8;
}

/* Responsive styles */
@media (max-width: 992px) {
  .navbarCollapse {
    max-height: calc(100vh - 60px);
    overflow-y: auto;
  }

  .navbarCollapseShow {
    background-color: var(--primary);
    padding: 1rem;
    margin: 0 -1rem;
    box-shadow: var(--shadow-md);
  }

  .navLink {
    padding: 0.75rem 1rem;
  }

  .navLinkActive::after {
    left: 1rem;
    right: 1rem;
  }
}

@media (max-width: 768px) {
  .brandingTitle {
    font-size: 1.0rem;
    margin-top: 10px;
    letter-spacing: 4px;
  }
}

@media (max-width: 576px) {
  .brandLogo {
    width: 30px;
    height: 30px;
  }

  .brandLogoPrimary, .brandLogoTertiary {
    font-size: 1.5rem;
  }
}
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Remove the green background color from body */
body {
  background-color: white;
}

.maintenance-body {
  margin: 0 !important;
  padding: 0 !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

/* Fix for white borders in parent containers */
.maintenance-body main {
  position: relative;
  z-index: 1;
  padding: 0 !important;
  margin: 0 !important;
  min-height: 100vh;
}

/* Ensure footer is visible and at bottom */
footer {
  position: relative;
  z-index: 10;
  background-color: #212529 !important;
}

.maintenance-body footer {
  position: relative !important;
}

/* Hide the .bg div as we'll use body background instead */
.maintenance-body .bg {
  display: none;
}

.maintenance-page {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: 6rem 0 2rem 0;
  margin-bottom: 1rem;
  min-height: calc(100vh - 10rem);
}

.maintenance-container {
  width: 100%;
  padding: 2rem 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.maintenance-box {
  background-color: white;
  border: 2px solid #e9ecef;
  transition: all 0.3s ease;
  width: 100%;
  margin: 0 auto;
  padding: clamp(1.5rem, 4vw, 2.5rem);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.maintenance-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15) !important;
}

.tools-container {
  display: flex;
  justify-content: center;
  gap: clamp(1.5rem, 4vw, 2.5rem);
  height: auto;
  padding: clamp(1rem, 3vw, 2rem) 0;
}

.tool {
  display: flex;
  align-items: center;
  justify-content: center;
  width: clamp(50px, 7vw, 70px);
  height: clamp(50px, 7vw, 70px);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  position: relative;
  transition: all 0.3s ease;
}

.tool i {
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
  font-size: clamp(1.2rem, 3.5vw, 1.8rem);
}

/* Site-specific colors */
.site-heading {
  color: #FC4C02; /* Strava orange */
}

.site-icon-primary {
  color: #FC4C02; /* Strava orange */
}

.site-icon-secondary {
  color: #767b28; /* Olive green from logo background */
}

.site-icon-accent {
  color: #4285F4; /* Google blue */
}

.site-icon-highlight {
  color: #EA4335; /* Google red */
}

.site-icon-dark {
  color: #343a40; /* Dark gray */
}

.tool.bounce {
  animation: bounce 0.5s ease;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

/* Spinning animation for the cog icon */
.fa-cog {
  animation: spin 4s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Hammering animation */
.fa-hammer {
  transform-origin: bottom right;
  animation: hammer 2s ease-in-out infinite;
}

@keyframes hammer {
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-30deg);
  }
}

/* Add some responsive styles */
@media (max-width: 992px) {
  .maintenance-page {
    padding: 5rem 0 2rem 0;
  }
}

@media (max-width: 768px) {
  .maintenance-page {
    padding: 4rem 0 2rem 0;
    margin-bottom: 4rem;
  }

  .tools-container {
    flex-wrap: wrap;
    height: auto;
    gap: clamp(1rem, 3vw, 1.5rem);
    padding: 1.5rem 0;
  }

  .tool {
    width: 55px;
    height: 55px;
  }

  .tool i {
    font-size: 1.6rem !important;
  }
}

/* Media query adjustments */
@media (max-width: 768px) {
  .maintenance-box {
    padding: 1.8rem;
  }
}

@media (max-width: 576px) {
  .maintenance-page {
    padding: 3rem 0 1.5rem 0;
  }

  .maintenance-box {
    padding: 1.5rem;
  }

  .tools-container {
    gap: 0.8rem;
    padding: 1rem 0;
  }

  .tool {
    width: 45px;
    height: 45px;
  }

  .tool i {
    font-size: 1.3rem !important;
  }

  .responsive-heading {
    font-size: 1.7rem;
  }

  .responsive-text {
    font-size: 1.1rem;
  }
}

/* Responsive Typography */
.responsive-heading {
  font-size: clamp(1.8rem, 5vw, 2.8rem);
  line-height: 1.3;
  font-weight: 700;
}

.responsive-text {
  font-size: clamp(1.2rem, 3vw, 1.6rem);
  line-height: 1.5;
  font-weight: 400;
}

.responsive-small {
  font-size: clamp(0.9rem, 2vw, 1.2rem);
  line-height: 1.4;
}

@media (max-width: 480px) {
  .maintenance-box {
    padding: 1.2rem;
  }

  .responsive-heading {
    font-size: 1.5rem;
    margin-bottom: 0.75rem !important;
  }

  .responsive-text {
    font-size: 1rem;
    margin-bottom: 0.75rem !important;
  }

  .responsive-small {
    font-size: 0.85rem;
  }

  .tools-container {
    padding: 0.75rem 0;
  }

  .tool {
    width: 40px;
    height: 40px;
  }

  .tool i {
    font-size: 1.1rem !important;
  }
}
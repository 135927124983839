.pageWrapper {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  flex: 1 0 auto;
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xl);
  width: 100%;
}

/* Responsive padding adjustments */
@media (max-width: 992px) {
  .contentContainer {
    padding-top: calc(var(--space-xl) + var(--space-md));
    padding-bottom: var(--space-lg);
  }
}

@media (max-width: 768px) {
  .contentContainer {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-md);
  }
}

@media (max-width: 576px) {
  .contentContainer {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-md);
  }
}

/* Landscape orientation adjustments */
@media (orientation: landscape) and (max-height: 500px) {
  .contentContainer {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-sm);
  }
}
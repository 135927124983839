:root {
  /* Colors */
  --primary: #212529;
  --primary-light: #3c4145;
  --secondary: #198754;
  --text-light: rgb(231,235,239);
  --text-light-muted: rgb(198,200,205);
  --bg-dark: rgba(0, 0, 0, 0.75);
  --bg-dark-light: rgba(0, 0, 0, 0.5);
  --border-light: rgba(255, 255, 255, 0.1);

  /* Touch feedback */
  --active-opacity: 0.7;
  --focus-ring-color: rgba(66, 153, 225, 0.6);
  --tap-highlight-color: transparent;

  /* Spacing */
  --space-xs: 0.25rem;
  --space-sm: 0.5rem;
  --space-md: 1rem;
  --space-lg: 1.5rem;
  --space-xl: 2rem;
  --space-xxl: 3rem;

  /* Typography */
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.5rem;
  --font-size-xxl: 2rem;

  /* Breakpoints */
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;

  /* Shadows */
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 8px rgba(0, 0, 0, 0.2);
  --shadow-lg: 0 10px 25px rgba(0, 0, 0, 0.15);

  /* Z-index layers */
  --z-index-navbar: 1030;
  --z-index-content: 5;
  --z-index-footer: 10;

  /* Animation */
  --transition-fast: 0.2s ease;
  --transition-normal: 0.3s ease;
}
/* Custom styling for FAQ accordion */
.custom-accordion .accordion-button:not(.collapsed) {
  color: #198754; /* Bootstrap success green for text */
  background-color: #d1e7dd; /* Light green background when expanded */
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.custom-accordion .accordion-button:focus {
  border-color: #75c294; /* Lighter green for the focus border */
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); /* Green box shadow with transparency */
}

.custom-accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23198754'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

/* Add green hover effect */
.custom-accordion .accordion-button:hover {
  background-color: #e8f4ef;
}

/* Add subtle green border when expanded */
.custom-accordion .accordion-item.active {
  border-left: 3px solid #198754;
}
/*
 * Global styles that apply across the application
 * Component-specific styles have been moved to CSS modules
 */

.hidden {
    display: none;
    /* visibility: "hidden" */
}

.page-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.page-container main {
    flex: 1 0 auto;
    position: relative;
    z-index: var(--z-index-content);
}

.page-container footer {
    flex-shrink: 0;
}

.branding {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.branding h1 {
    font-family: "Helvetica Neue";
    font-size: 1.5rem;
    margin: 0;
    letter-spacing: 7px;
}

@media (max-width: 768px) {
    .branding h1 {
        font-size: 1.0rem;
        margin-top: 10px;
    }
}

.branding h1 span {
    display: inline-block;
}

/* NavBar Logo Styles */
.brand-logo-primary {
    color: rgb(231,235,239);
    font-size: 2rem;
}

.brand-logo-secondary {
    color: rgb(231,235,239);
}

.brand-logo-tertiary {
    color: rgb(198,200,205);
    font-size: 2rem;
}

.brand-logo-quaternary {
    color: rgb(198,200,205);
}

/* Fixed navbar styling */
.navbar.fixed-top {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1030;
}

/* Navbar mobile adjustments */
@media (max-width: 992px) {
    .navbar-collapse {
        max-height: calc(100vh - 60px);
        overflow-y: auto;
        transition: all 0.3s ease;
    }

    .navbar-collapse.show {
        background-color: #212529;
        padding: 1rem;
        margin: 0 -1rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    body.navbar-expanded main {
        padding-top: 14rem;
        transition: padding-top 0.3s ease;
    }

    body.navbar-expanded .bg {
        padding-top: 4rem;
    }
}

/* For smaller mobile devices */
@media (max-width: 576px) {
    body.navbar-expanded main {
        padding-top: 16rem;
    }
}

/* Responsive adjustments */
@media (max-width: 576px) {
    .navbar-brand img {
        width: 30px;
        height: 30px;
    }

    .brand-logo-primary, .brand-logo-tertiary {
        font-size: 1.5rem;
    }

    .branding h1 {
        letter-spacing: 4px;
    }
}

/* Touch-friendly nav links */
.nav-link {
    padding: 0.5rem 0.75rem;
}

@media (max-width: 992px) {
    .nav-link {
        padding: 0.75rem 1rem;
    }
}

/* Footer Styles */
.footer {
    padding: 1rem 0;
    position: relative;
    z-index: 10;
    background-color: #212529;
    width: 100%;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Maintenance mode specific styles */
body.maintenance-body {
    padding-bottom: 0;
}

body.maintenance-body footer {
    position: relative !important;
    z-index: 100 !important;
}

/* Mobile Footer Overrides */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column !important;
    gap: 1.2rem !important;
  }

  .footer-content > div:nth-child(1) {
    order: 3;
    font-size: 0.8rem;
    margin-top: 0.8rem;
    text-align: center;
  }

  .footer-content > div:nth-child(2) {
    order: 2;
    width: 100%;
    justify-content: center !important;
    padding: 0.5rem 0;
  }

  .footer-content > div:nth-child(3) {
    order: 1;
    width: 100%;
    text-align: center !important;
    padding-top: 0.5rem;
  }

  .footer-content > div:nth-child(3) > div:last-child {
    margin-top: 1rem;
  }
}

/* Landscape orientation adjustments */
@media (orientation: landscape) and (max-height: 500px) {
  html, body {
    height: auto;
    overflow-y: auto;
  }

  .page-container {
    min-height: 100%;
    height: auto;
    position: relative;
  }

  .page-container main {
    padding-top: 3.5rem;
    padding-bottom: 3rem;
    min-height: calc(100vh - 3.5rem);
    overflow-y: auto;
  }

  /* Keep navbar fixed at top */
  .navbar.fixed-top {
    position: fixed;
  }

  body.navbar-expanded main {
    padding-top: 14rem;
    transition: padding-top 0.3s ease;
  }

  body.navbar-expanded .bg {
    padding-top: 4rem;
  }

  .footer {
    padding: 0.5rem 0;
    position: relative;
    bottom: 0;
    height: auto;
  }

  .footer-content {
    flex-direction: row !important;
    align-items: center;
    font-size: 0.8rem;
  }
}

/* Navbar expanded state - global body class */
body.navbar-expanded main {
    padding-top: 14rem;
    transition: padding-top var(--transition-normal);
}

body.navbar-expanded .bg {
    padding-top: 4rem;
}

/* Landscape orientation adjustments for body and html */
@media (orientation: landscape) and (max-height: 500px) {
  html, body {
    height: auto;
    overflow-y: auto;
  }
}
.homepageBody {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  overflow-x: hidden;
}

.container {
  padding: 0;
}

/* Portrait mode container */
.portraitContainer {
  display: flex;
  min-height: calc(100vh - 10rem);
  padding: 5rem 0 2rem 0;
  align-items: center;
  justify-content: center;
}

/* Landscape mode container */
.landscapeContainer {
  display: flex;
  min-height: calc(100vh - 10rem);
  padding: 5rem 0 2rem 0;
  align-items: center;
  justify-content: center;
}

/* Content box */
.contentBox {
  background: linear-gradient(135deg, var(--bg-dark) 0%, var(--bg-dark-light) 100%);
  padding: clamp(1.5rem, 4vw, 2.5rem);
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  border-radius: 8px;
  box-shadow: var(--shadow-lg);
  color: white;
  border: 2px solid var(--border-light);
  transition: all var(--transition-normal);
}

.contentBox:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2) !important;
}

.heading {
  font-weight: 700;
  font-size: clamp(1.8rem, 5vw, 2.8rem);
  letter-spacing: 0.5px;
  line-height: 1.3;
  margin-bottom: var(--space-lg);
}

.subheading {
  font-weight: 400;
  font-size: clamp(1.2rem, 3vw, 1.6rem);
  line-height: 1.5;
  opacity: 0.9;
  margin-bottom: var(--space-lg);
}

.paragraph {
  font-weight: 300;
  line-height: 1.7;
  font-size: clamp(1rem, 2vw, 1.2rem);
  margin-bottom: var(--space-lg);
}

.cta {
  display: inline-block;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: var(--space-md);
  border-radius: 4px;
  position: relative;
  z-index: 10;
  cursor: pointer;
  pointer-events: auto;
  touch-action: manipulation;
}

.cta:hover {
  transform: scale(1.05);
  box-shadow: var(--shadow-md);
}

.cta:active {
  transform: scale(0.98);
  opacity: var(--active-opacity);
}

.ctaImage {
  pointer-events: none;
  display: inline-block;
  border-radius: 4px;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .portraitContainer, .landscapeContainer {
    padding: 5rem 0 2rem 0;
  }
}

@media (max-width: 768px) {
  .portraitContainer, .landscapeContainer {
    padding: 4rem 0 2rem 0;
  }
}

@media (max-width: 576px) {
  .portraitContainer, .landscapeContainer {
    padding: 3rem 0 1.5rem 0;
  }

  .heading {
    font-size: clamp(1.5rem, 5vw, 2rem);
    margin-bottom: var(--space-md);
  }

  .subheading {
    font-size: clamp(1rem, 3vw, 1.4rem);
    margin-bottom: var(--space-md);
  }
}

/* Landscape orientation specific styles */
@media (orientation: landscape) and (max-height: 500px) {
  .landscapeContainer {
    padding: 2rem 0 1rem 0;
  }

  .contentBox {
    padding: var(--space-md);
  }

  .heading {
    margin-bottom: var(--space-sm);
  }

  .subheading {
    margin-bottom: var(--space-sm);
  }

  .paragraph {
    margin-bottom: var(--space-sm);
  }
}